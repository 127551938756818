import Select from 'react-select'

import styled from '@emotion/styled'

export const StyledSelect = styled(Select)`
  .react-select__control {
    border: 2px solid
      ${(p) => (p.valid ? '#2BD2B4' : p.isInvalid ? '#D01030' : '#9FB8E5')};
    min-height: 48px;
    border-radius: 6px;
  }
  .react-select__control--is-focused {
    box-shadow: 0 0 0 2px rgba(44, 133, 244, 0.6);
  }
  .react-select__value-container {
    font-weight: 600;
    font-size: 14px;
    padding-left: 16px;
    max-height: ${(p) =>
      p.dropdownContainerHeight ? p.dropdownContainerHeight : 'auto'};
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .react-select__placeholder {
    font-size: 14px;
    color: rgba(45, 46, 65, 0.3);
    font-style: italic;
    font-weight: 500;
  }
  .react-select__single-value {
    overflow: visible;
    padding: 0px 8px;
  }
  .react-select__multi-value {
    border-radius: 4px;
  }
  .react-select__option {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.4px;
  }
  .react-select__indicator-separator {
    background-color: #9fb8e5;
  }
  /* CSS below limits the height of the multiselect input for inputs with many selected items */
  .react-select__control {
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .react-select__control::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  /*
    'static' position on 'control' and 'value-container' ensures that 'indicators' position is
    relative to the input container. Otherwise, indicators would become invisible when user
    scrolls down.
  */
  .react-select__control {
    position: static;
  }
  .react-select__value-container {
    /* Padding ensures that selected items do not overlap with 'indicators' */
    padding-right: 73px;
    position: static;
  }
  .react-select__indicators {
    height: calc(100% - 5px);
    position: absolute;
    right: 0px;
  }
  .react-select__control--is-disabled {
    /*
      For disabled input we don't want to restrict height of the multiselect input, because
      scrolling is disabled and user would not be able to read all selected values.
    */
    max-height: none;
  }
`
