import { useIncidentDescription } from '../../../../features/incident-drawer/feed/hooks/useIncidentDescription'

import { Box, Flex } from '@chakra-ui/react'

import { IncidentCreatorType } from '@/features/incident-drawer'
import { IncidentType } from '@/graphql/generated/schemas'
import { DeviceType } from '@/graphql/generated/schemas'

import { ButtonTag } from './ButtonTag'
import { DeviceIcon } from './DeviceIcon'
import { FloorIcon } from './FloorIcon'
import { ManualIncidentDescription } from './ManualIncidentDescription'

interface IncidentDescriptionIProps {
  type: IncidentType
  description?: string
  floorName?: string
  floorId?: string
  deviceName?: string
  deviceType?: DeviceType
  deviceId?: string
  showButtonTag?: boolean
  creator?: IncidentCreatorType
}

export const IncidentDescription = ({
  type,
  creator,
  description,
  floorName,
  floorId,
  deviceName,
  deviceType,
  deviceId,
  showButtonTag = true,
}: IncidentDescriptionIProps) => {
  const { message, onDeviceClick, onFloorClick } = useIncidentDescription({
    deviceId,
    deviceType,
    floorId,
    type,
  })

  switch (type) {
    case IncidentType.DoorHeldOpen:
    case IncidentType.DoorHeldClose:
    case IncidentType.ForcedEntry:
    case IncidentType.LineCrossing:
    case IncidentType.Alarm:
      return (
        <Box
          alignItems='center'
          as='span'
          display='flex'
          flexDirection='row'
          mt='5px'
          width='100%'
        >
          {showButtonTag ? (
            <Box alignItems='center' display='flex' flexDir='row' mr='1'>
              <ButtonTag onClick={onDeviceClick}>
                <DeviceIcon type={deviceType} />
                {deviceName}
              </ButtonTag>
              on
            </Box>
          ) : (
            <Flex maxW='40%' mr='1' noOfLines={1}>
              <DeviceIcon type={deviceType} />
              <Box alignItems='center' as='span' mr='1'>
                {`${deviceName} on `}
              </Box>
            </Flex>
          )}

          {showButtonTag ? (
            <ButtonTag onClick={onFloorClick}>
              <FloorIcon />
              {floorName}
            </ButtonTag>
          ) : (
            <Flex maxW='30%' mr='1' noOfLines={1}>
              <FloorIcon />
              {`${floorName} `}
            </Flex>
          )}
          <Flex>{message}</Flex>
        </Box>
      )
    case IncidentType.Tailgating:
      return <span>{message}</span>
    default:
      return (
        <ManualIncidentDescription
          creator={creator}
          description={description}
        />
      )
  }
}
