// App router paths
export const HOME = '/'
export const GLOBAL = '/global'
export const FACILITIES = '/facilities'
export const INCIDENTS = '/incidents'
export const DEVICES = '/devices'
export const DASHBOARD = '/dashboard'
export const DASHBOARD_PROGRAM_HEALTH = '/dashboard/program-health'
export const DASHBOARD_OUTSTANDING_INCIDENTS =
  '/dashboard/outstanding-incidents'
export const METRICS_DASHBOARD = '/metrics-dashboard'
export const STANDARD_OPERATING_PROCEDURES = '/standard-operating-procedures'
export const EMERGENCY_CALL_LOG = '/emergency-call-log'
export const SUBMIITED_TOURS = '/submitted-tours'
export const GATEWAYS = '/gateways'
export const CASE_MANAGEMENT = '/case-management'
export const INCIDENT_RULES = '/incident-rules'
export const CREATE_INCIDENT_RULE = '/create-incident-rule'
export const SETTINGS_MANAGEMENT = '/settings/account-management'
export const FLOOR_PLAN = '/floor-plan/'
