export const AlarmAssociation = ({
  strokeColor = '#1A202C',
}: {
  strokeColor?: string
}) => (
  <svg
    fill='none'
    height='20'
    viewBox='0 0 26 20'
    width='26'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='surface1' transform='translate(11,2)'>
      <path
        d='M 6.015625 4.667969 C 6.015625 8.625 6.066406 8.90625 6.8125 9.515625 C 7.753906 10.269531 9.101562 10.339844 10.160156 9.683594 C 11.140625 9.058594 11.179688 8.933594 11.21875 4.765625 L 11.261719 1.117188 L 6.015625 1.117188 Z M 10.460938 2.089844 C 10.460938 2.355469 10.371094 2.371094 8.566406 2.371094 C 6.761719 2.371094 6.667969 2.355469 6.667969 2.089844 C 6.667969 1.824219 6.761719 1.8125 8.566406 1.8125 C 10.371094 1.8125 10.460938 1.824219 10.460938 2.089844 Z M 10.042969 3.164062 C 10.003906 3.386719 9.808594 3.414062 8.589844 3.457031 C 7.257812 3.5 7.191406 3.484375 7.191406 3.207031 C 7.191406 2.941406 7.269531 2.925781 8.644531 2.925781 C 9.953125 2.925781 10.082031 2.957031 10.042969 3.164062 Z M 10.238281 5.292969 L 10.722656 5.449219 L 10.722656 6.84375 C 10.722656 8.152344 10.710938 8.25 10.398438 8.554688 C 9.535156 9.40625 7.597656 9.378906 6.773438 8.5 C 6.421875 8.125 6.410156 8.039062 6.410156 6.773438 L 6.410156 5.449219 L 7.230469 5.238281 C 8.09375 5.019531 9.375 5.03125 10.238281 5.292969 Z M 10.238281 5.292969 '
        fill={strokeColor}
      />
      <path
        d='M 7.492188 5.742188 L 6.929688 5.867188 L 6.929688 6.871094 C 6.929688 7.425781 6.984375 7.941406 7.035156 8.027344 C 7.375 8.585938 9.207031 8.765625 9.859375 8.320312 C 10.171875 8.09375 10.199219 8.011719 10.199219 6.980469 L 10.199219 5.882812 L 9.625 5.730469 C 8.945312 5.546875 8.265625 5.546875 7.492188 5.742188 Z M 7.492188 5.742188 '
        fill={strokeColor}
      />
      <path
        d='M 2.5625 2.550781 C 2.160156 3.039062 1.832031 3.5 1.832031 3.566406 C 1.832031 3.636719 2.601562 4.542969 3.53125 5.574219 L 5.230469 7.457031 L 4.316406 4.601562 C 3.804688 3.039062 3.375 1.730469 3.347656 1.714844 C 3.324219 1.6875 2.96875 2.0625 2.5625 2.550781 Z M 2.5625 2.550781 '
        fill={strokeColor}
      />
      <path
        d='M 13.757812 1.953125 C 13.3125 3.222656 12.03125 7.285156 12.070312 7.328125 C 12.097656 7.359375 12.855469 6.519531 13.769531 5.476562 L 15.417969 3.566406 L 15.039062 3.082031 C 14.828125 2.800781 14.476562 2.355469 14.265625 2.089844 L 13.875 1.605469 Z M 13.757812 1.953125 '
        fill={strokeColor}
      />
      <path
        d='M 1.570312 6.953125 C 1.570312 7.566406 1.453125 7.511719 3.242188 7.800781 C 3.765625 7.898438 4.222656 7.941406 4.25 7.914062 C 4.277344 7.886719 3.6875 7.539062 2.941406 7.132812 L 1.570312 6.410156 Z M 1.570312 6.953125 '
        fill={strokeColor}
      />
      <path
        d='M 14.121094 7.175781 C 13.367188 7.59375 12.816406 7.929688 12.90625 7.929688 C 13.246094 7.957031 15.117188 7.636719 15.402344 7.523438 C 15.628906 7.425781 15.691406 7.273438 15.691406 6.898438 C 15.691406 6.632812 15.652344 6.410156 15.601562 6.421875 C 15.535156 6.421875 14.882812 6.757812 14.121094 7.175781 Z M 14.121094 7.175781 '
        fill={strokeColor}
      />
      <path
        d='M 4.8125 8.707031 C 3.804688 9.417969 3.609375 10.382812 4.328125 11.148438 C 4.867188 11.71875 5.308594 11.941406 6.5 12.277344 C 8.890625 12.917969 11.953125 12.316406 12.996094 11.007812 C 13.558594 10.285156 13.367188 9.433594 12.515625 8.820312 C 11.824219 8.320312 11.679688 8.304688 11.585938 8.707031 C 11.457031 9.277344 10.632812 10.128906 9.964844 10.40625 C 8.316406 11.078125 6.410156 10.4375 5.753906 8.972656 C 5.597656 8.640625 5.425781 8.359375 5.386719 8.375 C 5.335938 8.375 5.085938 8.527344 4.8125 8.707031 Z M 4.8125 8.707031 '
        fill={strokeColor}
      />
      <path
        d='M 2.460938 11.0625 C -0.105469 13.152344 2.640625 15.714844 7.832031 16.121094 C 10.734375 16.328125 13.953125 15.394531 15.195312 13.960938 C 15.691406 13.375 15.71875 13.304688 15.664062 12.652344 C 15.613281 12.109375 15.511719 11.859375 15.15625 11.480469 C 14.765625 11.035156 14.136719 10.589844 13.925781 10.589844 C 13.886719 10.589844 13.859375 11.035156 13.859375 11.566406 C 13.859375 12.652344 13.703125 13.109375 13.113281 13.667969 C 12.539062 14.210938 12.148438 14.433594 11.167969 14.769531 C 8.878906 15.519531 5.921875 15.160156 4.328125 13.933594 C 3.582031 13.363281 3.398438 12.890625 3.398438 11.632812 C 3.398438 10.40625 3.324219 10.367188 2.460938 11.0625 Z M 2.460938 11.0625 '
        fill={strokeColor}
      />
      <path
        d='M 3.921875 12.136719 C 3.921875 14.476562 9.585938 15.464844 12.410156 13.625 C 12.945312 13.261719 13.339844 12.609375 13.339844 12.023438 L 13.339844 11.578125 L 12.59375 12.066406 C 11.378906 12.847656 10.644531 13.027344 8.628906 13.027344 C 6.617188 13.027344 5.441406 12.734375 4.472656 11.980469 L 3.921875 11.566406 Z M 3.921875 12.136719 '
        fill={strokeColor}
      />
    </g>
    <path
      d='M11.85 3.654a.977.977 0 00-.804-.215l-1.991.456.17-.633c.142-.53-.218-1.103-.8-1.258L3.109.579c-.58-.156-1.179.16-1.32.69L.76 5.1c-.142.53.218 1.103.799 1.259l5.334 1.43c.582.155 1.18-.161 1.321-.691l.175-.65 1.496 1.39c.338.311.866.324 1.185.025a.673.673 0 00.203-.349l.83-3.095c.068-.256-.039-.541-.254-.764z'
      fill={strokeColor}
    ></path>
  </svg>
)
