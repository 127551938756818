import { SavedFilterI } from '@/features/incidents-table'
import {
  ActiveIncidentsFilterQuery,
  FloorsByFacilityIdsQuery,
  IncidentFiltersEnrichmentQuery,
} from '@/graphql/generated/operations'
import {
  DeviceType,
  IncidentFilterCriteria,
  IncidentFilterInput,
  IncidentPriority,
  IncidentSource,
  IncidentStatus,
  IncidentType,
  MutedStatus,
} from '@/graphql/generated/schemas'
import {
  incidentTypeOptions as automatedTypeOptions,
  createdAtIntervalOptions,
  incidentStatusOptions,
  mutedOptions,
  priorityOptions,
  sourceTypeOptions,
} from '@/redux/incidents/incidentsFilterOptions'
import { ExtraIncidentFilterCriteria } from '@/redux/incidents/incidentsSlice'
import { getIncidentCategoryOptions } from '@/utils/forms/optionBuilders'

import {
  FilterOptionI,
  IncidentFiltersFormInputsI,
} from '../../features/incidents-table/incidents-filter-drawer/types/form'
import {
  combineIncidentType,
  combineSourceType,
  filterByType,
  getCreatedAtGteTimestamp,
  getFilterFromLabels,
  getFilterFromOptions,
  getLabels,
  getSingleFilterFromOptions,
  removeNullValues,
  removeTypename,
} from './utils'

/**
 * These functions implement conversions between incident filter form state and filter criteria.
 */

const now = Date.now()

export const getMutedFilterCriteria = (
  filter: FilterOptionI<MutedStatus>
): IncidentFilterCriteria =>
  filter ? { mutedStatus: filter.value } : { mutedStatus: MutedStatus.All }

export const getFacilityIdInFilterCriteria = (
  filter: FilterOptionI[]
): IncidentFilterCriteria =>
  filter?.length ? { facilityIdIn: filter?.map((f) => f.value) } : {}

export const getFloorIdInFilterCriteria = (
  filter: FilterOptionI[]
): IncidentFilterCriteria =>
  filter?.length ? { floorIdIn: filter?.map((f) => f.value) } : {}

export const getCreatedAtGteIntervalFilterCriteria = (
  filter: FilterOptionI<number>
): IncidentFilterCriteria =>
  filter?.value ? { createdAtGteInterval: filter.value } : {}

export const getStatusInFilterCriteria = (
  filter: FilterOptionI<IncidentStatus>[]
): IncidentFilterCriteria =>
  filter?.length ? { statusIn: filter?.map((f) => f.value) } : {}

export const getPriorityInFilterCriteria = (
  filter: FilterOptionI<IncidentPriority>[]
): IncidentFilterCriteria =>
  filter?.length ? { priorityIn: filter?.map((f) => f.value) } : {}

export const getDeviceIdInFilterCriteria = (
  filter: FilterOptionI[]
): IncidentFilterCriteria =>
  filter?.length ? { deviceIdIn: filter?.map((f) => f.value) } : {}

export const getIncidentSourceCombinedFilterCriteria = (
  filter: FilterOptionI<IncidentSource | DeviceType>[]
): IncidentFilterCriteria => {
  const sourcesArray = filterByType(filter, IncidentSource)
  const deviceTypesArray = filterByType(filter, DeviceType)
  const combinedFilters = [
    sourcesArray.length > 0 && { sourceIn: sourcesArray },
    deviceTypesArray.length > 0 && { deviceTypeIn: deviceTypesArray },
  ].filter(Boolean)

  return combinedFilters.length > 0
    ? ({ OR: combinedFilters } as unknown as IncidentFilterCriteria)
    : {}
}

export const getIncidentTypeCombinedFilterCriteria = (
  filter: FilterOptionI<IncidentType | string>[]
): IncidentFilterCriteria => {
  const incidentTypesArray = filterByType(filter, IncidentType)

  /**
   * This array should contain all values from MultiFilter that are NOT corresponding to
   * IncidentType's. For example if the MultiFilter contains following values:
   *
   * [
   *  { value: "TAILGATING", label: "..." },
   *  { value: "DOOR_HELD_OPEN", label: "..." },
   *  { value: "f57edeb8-ba00-4206-bfd5-4c4869d59d0a", label: "..." },
   *  { value: "b17e235f-1864-4b44-86ae-3fd51f68adc2", label: "..." }
   * ]
   *
   * then the resulting incidentCategoryIdsArray would be:
   *
   * [ "f57edeb8-ba00-4206-bfd5-4c4869d59d0a", "b17e235f-1864-4b44-86ae-3fd51f68adc2" ]
   *
   *  */
  const incidentCategoryIdsArray =
    filter
      ?.map((f) => f.value)
      .filter(
        (v) => !incidentTypesArray.map((t) => t.toString()).includes(v)
      ) || []

  const combinedFilters = [
    incidentTypesArray.length > 0 && { typeIn: incidentTypesArray },
    incidentCategoryIdsArray.length > 0 && {
      manualIncidentCategoryIdIn: incidentCategoryIdsArray,
    },
  ].filter(Boolean)

  return combinedFilters.length > 0
    ? ({ AND: [{ OR: combinedFilters }] } as unknown as IncidentFilterCriteria)
    : {}
}

/**
 * Conversion between Redux state and IncidentFilterInput.
 *
 * It does only small change of building `createdAtGte` based on
 * selected `createdAtGteInterval`.
 */
export const buildFilterInput = (
  savedFilter: IncidentFilterCriteria,
  extraFilter?: ExtraIncidentFilterCriteria
): IncidentFilterInput => {
  const savedFilterInput: IncidentFilterInput = {
    ...savedFilter,
    ...(savedFilter?.createdAtGteInterval
      ? {
          createdAtGte: getCreatedAtGteTimestamp(
            now,
            savedFilter.createdAtGteInterval
          ),
        }
      : {}),
  }

  return JSON.parse(
    JSON.stringify({
      ...removeNullValues(savedFilterInput || {}),
      ...removeNullValues(extraFilter || {}),
    })
  )
}

/**
 * Conversion between incident filter form state and IncidentFilterCriteria.
 *
 * It is used for:
 *  - building `createSavedFilter` mutation input from form state
 *  - building `updateSavedFilter` mutation input from form state
 *  - applying filters in standard mode
 */
export const buildFilterCriteria = (
  formState: IncidentFiltersFormInputsI
): IncidentFilterCriteria => {
  return {
    ...getFacilityIdInFilterCriteria(formState.facilityName),
    ...getFloorIdInFilterCriteria(formState.floorName),
    ...getCreatedAtGteIntervalFilterCriteria(formState.createdAt),
    ...getStatusInFilterCriteria(formState.incidentStatus),
    ...getPriorityInFilterCriteria(formState.priority),
    ...getIncidentSourceCombinedFilterCriteria(formState.sourceType),
    ...getIncidentTypeCombinedFilterCriteria(formState.incidentType),
    ...getMutedFilterCriteria(formState.mutedStatus),
    ...getDeviceIdInFilterCriteria(formState.deviceName),
  }
}

/**
 * Conversion between IncidentFilterCriteria and filter form state.
 *
 * It requires providing extra data to generate form options labels.
 *
 * It is used for:
 *  - building filter form state from `savedFilter` query response
 */
export const buildFormState = (
  criteria: IncidentFilterCriteria,
  enrichmentData: IncidentFiltersEnrichmentQuery & FloorsByFacilityIdsQuery
): IncidentFiltersFormInputsI => {
  const criteriaFacilityIds = criteria?.facilityIdIn || []
  const criteriaFloorIds = criteria?.floorIdIn || []
  const criteriaDeviceIds = criteria?.deviceIdIn || []
  const criteriaCreatedAt = criteria?.createdAtGteInterval
  const criteriaSourceType = combineSourceType(criteria?.OR)
  const criteriaIncidentType = combineIncidentType(criteria?.AND?.[0]?.OR)
  const criteriaPriorityStatus = criteria?.priorityIn || []
  const criteriaIncidentStatus = criteria?.statusIn || []

  const incidentTypeOptions = [
    ...automatedTypeOptions,
    ...getIncidentCategoryOptions(enrichmentData),
  ]

  const { facilityLabels, floorLabels, deviceLabels } =
    getLabels(enrichmentData)

  return {
    facilityName: getFilterFromLabels(criteriaFacilityIds, facilityLabels),
    floorName: getFilterFromLabels(criteriaFloorIds, floorLabels),
    deviceName: getFilterFromLabels(criteriaDeviceIds, deviceLabels),
    createdAt: getSingleFilterFromOptions(
      criteriaCreatedAt,
      createdAtIntervalOptions
    ),
    incidentStatus: getFilterFromOptions(
      criteriaIncidentStatus,
      incidentStatusOptions
    ),
    sourceType: getFilterFromOptions(criteriaSourceType, sourceTypeOptions),
    priority: getFilterFromOptions(criteriaPriorityStatus, priorityOptions),
    incidentType: getFilterFromOptions(
      criteriaIncidentType,
      incidentTypeOptions
    ),
    mutedStatus: getSingleFilterFromOptions(
      criteria?.mutedStatus,
      mutedOptions
    ),
  }
}

/**
 * Conversion between data returned from `activeFilter` query and IncidentFilterCriteria.
 *
 * Both objects have the same structure but return data contains __typename fields and null values
 * which should be removed because they cause errors in the `incidents` query.
 *
 * It is used for:
 *  - synchronizing DB state with active filter Redux state
 */
export const buildActiveFilterCriteria = (
  data: ActiveIncidentsFilterQuery
): IncidentFilterCriteria => {
  const criteria = data?.activeFilter?.filterCriteria?.incident || {}
  const sourceCriteria = criteria?.OR
  const typeCriteria = criteria?.AND?.[0]?.OR

  const filter = removeNullValues({
    ...removeTypename(criteria),
    ...(sourceCriteria
      ? { OR: sourceCriteria.map((c) => removeTypename(c)) }
      : {}),
    ...(typeCriteria
      ? { AND: [{ OR: typeCriteria.map((c) => removeTypename(c)) }] }
      : {}),
  })

  return filter
}

export const buildActiveFilter = (
  data: ActiveIncidentsFilterQuery
): SavedFilterI => {
  const id = data?.activeFilter?.id
  const name = data?.activeFilter?.name
  if (id !== undefined && name !== undefined) {
    return { id, name }
  }
  return null
}

/**
 * Extends the given `IncidentFilterInput` by ensuring that the `mutedStatus` filter is propagated
 * to all AND and OR filter expressions. This is necessary because the backend treats
 * a missing `mutedStatus` value as `NOT_MUTED`, which can lead to unintended filtering behavior.
 *
 * Without this propagation, filters using AND and OR expressions may implicitly exclude
 * muted incidents, causing incorrect results.
 */
export const extendMutedFilter = (
  filters: IncidentFilterInput,
  shouldOnlyShowAlarmed: boolean
): IncidentFilterInput => {
  // If shouldOnlyShowAlarmed is true, mutedStatus should be overwritten
  const mutedStatus = shouldOnlyShowAlarmed
    ? MutedStatus.NotMuted
    : filters.mutedStatus

  const orFilter = filters?.OR?.[0]
  const andFilter = filters?.AND?.[0]
  const andOrFilter = andFilter?.OR?.[0]

  const hasOr = orFilter !== undefined
  const hasAnd = andFilter !== undefined
  const hasMuted = mutedStatus !== undefined

  if (!hasMuted) {
    return filters
  }

  const extendedOr = hasOr ? [{ ...orFilter, mutedStatus }] : filters?.OR
  const extendedAnd = hasAnd
    ? [{ OR: [{ ...andOrFilter, mutedStatus }], mutedStatus }]
    : filters?.AND

  return { ...filters, mutedStatus, AND: extendedAnd, OR: extendedOr }
}
