import React from 'react'

import { Badge, BadgeProps } from '@chakra-ui/react'

interface ResultBadgeIProps extends Omit<BadgeProps, 'children'> {
  isValid: boolean
  message: string
}

const successBackgroundColor = '#E1FDDF'
const successColor = '#62856D'

const errorBackgroundColor = '#FFDAD6'
const errorColor = '#931100'

// TODO - Make CaseStatusBadge "dumber" and we could likely merge these two
//  Badges into a more generic solution w/o duplicated code

export const ResultBadge = ({
  isValid,
  message,
  ...badgeProps
}: ResultBadgeIProps) => {
  return (
    <Badge
      bgColor={isValid ? successBackgroundColor : errorBackgroundColor}
      borderRadius='5px'
      color={isValid ? successColor : errorColor}
      fontWeight={600}
      lineHeight='16px'
      p={2}
      {...badgeProps}
    >
      {message}
    </Badge>
  )
}
