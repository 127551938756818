import { Box } from '@chakra-ui/react'
import { css } from '@emotion/react'

interface ButtonTagIProps {
  onClick?: () => void
  children?: React.ReactNode
}

export const ButtonTag = ({ onClick, children }: ButtonTagIProps) => {
  return (
    <Box
      _hover={{
        backgroundColor: 'link.100',
        color: 'white',
      }}
      as='button'
      backgroundColor='white'
      color='black'
      css={css`
        &:hover .icon path:not(:first-of-type) {
          stroke: white;
        }
      `}
      display='flex'
      flexDirection='row'
      noOfLines={1}
      onClick={onClick}
      role='group'
      // Apply hover style to nested icons
      style={{
        fontWeight: 'bold',
        marginRight: '5px',
        padding: '1px 7px 1px 7px',
        display: 'flex',
        border: '2px solid #BBB',
        borderRadius: '10px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {children}
    </Box>
  )
}
