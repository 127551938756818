import { useMemo } from 'react'

import { RowModel } from '@tanstack/react-table'

import { Tbody, Td, Tr } from '@chakra-ui/react'

import { ROW_HEIGHT } from './TableBody'

interface TableSpacerIProps<DataT> {
  rowModel: RowModel<DataT>
  rowHeight?: number
  minHeight?: number
}

export const TableSpacer = <DataT,>({
  rowModel,
  rowHeight = ROW_HEIGHT,
  minHeight,
}: TableSpacerIProps<DataT>) => {
  if (!rowModel?.rows) {
    return null
  }
  const rows = rowModel.rows
  const totalRows = rows.length
  const visibleColumns = rows[0]?.getVisibleCells()?.length ?? 0

  const spacerHeight = useMemo(() => {
    if (!minHeight) return 0

    const contentHeight = totalRows * rowHeight
    return Math.max(0, minHeight - contentHeight)
  }, [minHeight, totalRows, rowHeight])

  return (
    <Tbody position='relative'>
      {spacerHeight > 0 && (
        <Tr
          _after={{
            content: '""',
            display: 'block',
            height: '100%',
          }}
          aria-hidden='true'
          border='none'
          height={`${spacerHeight}px`}
        >
          <Td border='none' colSpan={visibleColumns} padding={0} />
        </Tr>
      )}
    </Tbody>
  )
}
