import { Grid, Text } from '@chakra-ui/react'

import { IncidentCreatorType } from '@/features/incident-drawer'
import { creatorRoleName, formatFullName } from '@/utils/formats'

interface ButtonTagIProps {
  description: string
  creator?: IncidentCreatorType
}

export const ManualIncidentDescription = ({
  description,
  creator,
}: ButtonTagIProps) => {
  return (
    <>
      <span>{description}</span>
      {creator && (
        <Grid gap='20px 0px' mb='20px' mt='20px' templateColumns='175px 1fr'>
          <>
            <Text fontSize='14px' fontWeight={700}>
              Reporting Person
            </Text>
            <Text fontSize='14px' fontWeight={500}>
              {`${creatorRoleName(creator.roleName)} ${formatFullName(
                creator
              )}`}
            </Text>
          </>
        </Grid>
      )}
    </>
  )
}
