import { BiDoorOpen } from 'react-icons/bi'

import { Icon } from '@chakra-ui/react'

import { Alarm, Camera } from '@/components/icons'
import { DeviceType } from '@/graphql/generated/schemas'

export const DeviceIcon = ({ type }: { type: DeviceType }) => {
  if (type === DeviceType.Alarm) {
    return (
      <Icon
        alignSelf='center'
        as={Alarm}
        boxSize='22px'
        className='icon'
        mr='1'
        strokeColor='#000'
      />
    )
  }
  if (type === DeviceType.Door) {
    return <Icon as={BiDoorOpen} boxSize='24px' className='icon' mr='1' />
  } else {
    return (
      <Icon
        as={Camera}
        boxSize='24px'
        className='icon'
        mr='1'
        strokeColor='#000'
      />
    )
  }
}
