import { useEffect, useState } from 'react'

import { useCameraFeed } from './useCameraFeed'

import { useDeviceFeedDetailsQuery } from '@/graphql/generated/hooks'
import { DeviceType } from '@/graphql/generated/schemas'

import {
  DeviceFeedIProps,
  IncidentPlayerError,
  VideoFeedEvent,
} from '../types/types'

export function useFindDeviceFeed(
  deviceId: string,
  onEvent?: (event: VideoFeedEvent) => void
): DeviceFeedIProps {
  const [availableCamerasIds, setAvailableCamerasIds] = useState<string[]>([])
  const [currentCameraId, setCurrentCameraId] = useState<string>()

  const {
    data,
    loading: isDeviceDataLoading,
    error: deviceQueryError,
  } = useDeviceFeedDetailsQuery({
    variables: { id: deviceId },
    skip: !deviceId,
  })

  const stream = useCameraFeed(currentCameraId, onEvent)

  const nextCamera = () => {
    const currentIndex = availableCamerasIds.indexOf(currentCameraId)
    const newIndex = (currentIndex + 1) % availableCamerasIds.length
    setCurrentCameraId(availableCamerasIds[newIndex])
  }

  const previousCamera = () => {
    const currentIndex = availableCamerasIds.indexOf(currentCameraId)
    let newIndex = 0
    if (currentIndex === 0) {
      newIndex = availableCamerasIds.length - 1
    } else {
      newIndex = currentIndex - 1
    }
    setCurrentCameraId(availableCamerasIds[newIndex])
  }

  useEffect(() => {
    if (data) {
      if (
        data.device?.type === DeviceType.Door ||
        data.device?.type === DeviceType.Alarm
      ) {
        const cameras =
          data.device?.tailgateConns?.edges
            .map((conn) =>
              conn.node.cameras?.edges.map((camera) => camera.node.id)
            )
            .reduce((result, current) => result.concat(current), []) || []
        setAvailableCamerasIds(cameras)
      }

      if (data.device?.type === DeviceType.Camera) {
        setAvailableCamerasIds([data.device?.id])
      }
    }
  }, [data])

  useEffect(() => {
    setCurrentCameraId(availableCamerasIds[0])
  }, [availableCamerasIds])

  const deviceError = deviceQueryError
    ? IncidentPlayerError.ERROR_FETCHING_STREAM
    : null

  const error = deviceError || stream.errorMessage

  return {
    isDeviceDataLoading,
    stream,
    nextCamera,
    previousCamera,
    hasCamera: availableCamerasIds?.length > 0,
    availableCamerasIds,
    errorMessage: error,
  }
}
